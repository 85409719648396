import React, { useEffect, useState } from "react"
import { useShoppingCart } from "use-shopping-cart"
import tw, { styled } from "twin.macro"
import { useForm } from "react-hook-form"
import { useTransition, animated } from "react-spring"
const formatLineItems = require("use-shopping-cart/src/serverUtil")
  .formatLineItems

console.log(formatLineItems)

const CartWrapper = styled.div`
  .test {
    position: fixed;
    top: 0px;
    z-index: 5000;
    right: 0;
    height: calc(100%);
    max-height: calc(100%);
    background: #d8d4c9;
    padding: 25px;
    @media (max-width: 1280px) {
      width: 150%;
    }
    @media (max-width: 1000px) {
      width: 160%;
    }
    @media (max-width: 600px) {
      width: 300%;
      overflow: scroll;
    }
  }
  textarea {
    width: 350px;
    height: 125px;
    resize: none;
    padding: 5px;
    border: none;
    @media (max-width: 600px) {
      height: 50px;
      width: 200px;
    }
  }
  .submit {
    display: block;
    background: #78807a;
    border: none;
    color: #fff;
    width: 100px;
    text-align: center;
    margin: 30px 0 0 0;
    padding: 10px;
    border-radius: 30px;
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  input {
    &:focus {
      outline: none;
    }
  }
  .total {
    font-weight: 700;
  }
`

const CartItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 350px;
  align-items: center;
  padding-left: 25px;
  a {
    text-align: left;
    font-size: 12px;
    &:hover {
      p:first-child {
        color: #78807a;
        font-weight: 700;
      }
    }
  }
  button {
    width: 25px;
    height: 25px;
    background: #78807a;
    border: none;
    color: #fff;
    font-size: 22px;
    line-height: 0px;
    border-radius: 30px;
    @media (max-width: 600px) {
      transform: translateX(-15px);
    }
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
`

const Pickup = styled.div`
  width: 20%;
  margin-top: 20px;
  line-height: 1.625;
  padding-bottom: 20px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const MainWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`

const ScrollMe = styled.div`
  flex-grow: 0.2;
  overflow: auto;
  min-height: 2em;
`

const FixMe = styled.div`
  flex-shrink: 0;
`

export function CartItems({ location, close }) {
  // const transition = useSpring({
  //   transform: "translate3d(80%,0,0)",
  //   from: { transform: "translate3d(100%,0,0)" },
  // })

  const transitions = useTransition(close, null, {
    from: { transform: "translate3d(100%,0,0)" },
    enter: { transform: "translate3d(75%,0,0)" },
    leave: { transform: "translate3d(100%,0,0)" },
  })

  const [delivery, setDelivery] = useState("")
  const [giftMessage, setGiftMessage] = useState("")

  console.log(delivery)

  const handleSubmitCheckout = async event => {
    event.preventDefault()

    if (cartCount > 0) {
      console.log("cart details")
      console.log(cartDetails)
      console.log("cart details")

      console.log(delivery)

      let cartEntryArr = []

      console.log("cart details")
      console.log(JSON.stringify(cartDetails))
      console.log("cart details")

      console.log("format line items")
      console.log(formatLineItems(cartDetails))
      console.log("format line items")

      const finalArr = formatLineItems(cartDetails)

      let fetchUrl
      if (location.origin === "http://localhost:8888") {
        fetchUrl = `http://localhost:8888/.netlify/functions/stripe-checkout-session-fix`
      } else if (location.origin === "http://dev.studioconscious.com") {
        fetchUrl = `https://studioconscious-dev.netlify.app/.netlify/functions/stripe-checkout-session-fix`
      } else {
        fetchUrl = "/.netlify/functions/stripe-checkout-session-fix"
      }

      const response = await fetch(fetchUrl, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          line_items: finalArr,
          values: {
            pickup: delivery,
          },
        }),
      })
        .then(res => {
          return res.json()
        })
        .catch(error => console.log(error))

      console.log(response)

      redirectToCheckout({ sessionId: response.sessionId })
    } else {
      setStatus("missing-items")
    }
  }

  const [status, setStatus] = useState("idle")

  const {
    cartDetails,
    clearCart,
    decrementItem,
    incrementItem,
    cartCount,
    removeItem,
    formattedTotalPrice,
    redirectToCheckout,
  } = useShoppingCart()

  const removeItemCount = param => {
    localStorage.setItem("county", true)
    removeItem(param)
  }

  // console.log("is cart empty")

  // console.log(cartDetails)

  // const [isEmpty, setIsEmpty] = useState(false)

  // useEffect(() => {
  //   if (cartDetails === {}) {
  //     setIsEmpty(true)
  //   }
  // }, [])

  // console.log("cart location")
  // console.log(location)

  // var pathname = location.pathname

  // pathname.indexOf(1)

  // pathname.toLowerCase()

  // pathname = pathname.split("/")[1]

  const cart = []
  // Note: Object.keys().map() takes 2x as long as a for-in loop
  for (const sku in cartDetails) {
    const cartEntry = cartDetails[sku]

    let pathname1 = ""

    if (cartCount > 0) {
      console.log(cartEntry)

      var pathname = cartEntry.product_data.pathname

      pathname.indexOf(1)

      pathname.toLowerCase()

      pathname1 = pathname.split("/")[1]

      console.log(pathname1)
      console.log("140")
    }

    // all of your basic product data still exists (i.e. name, image, price)
    cart.push(
      <CartItemWrapper>
        {/* image here */}
        <a
          href={`/${pathname1}/${cartEntry.name
            .replace(/\s/g, "-")
            .toLowerCase()}/${cartEntry.id}`}
        >
          <p>{cartEntry.name}</p>
          {/* formatted total price of that item */}
          <p>{cartEntry.formattedValue}</p>
        </a>

        {/* <p>Quantity: {cartEntry.quantity}</p> */}
        {/* What if we want to remove one of the item... or add one */}
        {/* <button
          onClick={() => decrementItem(cartEntry.sku)}
          aria-label={`Remove one ${cartEntry.name} from your cart`}
        >
          -
        </button>
        <button
          onClick={() => incrementItem(cartEntry.sku)}
          aria-label={`Add one ${cartEntry.name} to your cart`}
        >
          +
        </button> */}

        {/* What if we don't want this product at all */}
        <button
          onClick={() => removeItemCount(cartEntry.price_id)}
          aria-label={`Remove all ${cartEntry.name} from your cart`}
        >
          -
        </button>
      </CartItemWrapper>
    )
  }

  return (
    <>
      <CartWrapper>
        {transitions.map(({ props, item, key }) => {
          return (
            <>
              {item && (
                <animated.div className="test" style={props} key={key}>
                  <MainWrap>
                    <h2>Shopping Cart</h2>
                    <ScrollMe>{cart}</ScrollMe>
                    <FixMe>
                      <p>
                        Total:{" "}
                        <span className="total">{formattedTotalPrice}</span>
                      </p>

                      <form onSubmit={handleSubmitCheckout}>
                        {/* include validation with required or other standard HTML validation rules */}
                        {/* <textarea
                      name="specialDelivery"
                      placeholder="please let us know your preferred pickup location A)West Edmonton or B) Sherwood Park"
                      onChange={e => setDelivery(e.target.value)}
                    /> */}

                        <Pickup>
                          Currently we are offering pickup in two locations;{" "}
                          <b>West Edmonton and Sherwood Park</b>. Pickup
                          location and time will be arranged via email. <br />
                        </Pickup>
                        <div>
                          <input
                            onChange={e => setDelivery(e.target.value)}
                            type="radio"
                            name="specialDelivery"
                            value="Sherwood Park"
                          />
                          <label>Sherwood Park</label>
                        </div>
                        <div>
                          <input
                            onChange={e => setDelivery(e.target.value)}
                            type="radio"
                            name="specialDelivery"
                            value="West Edmonton"
                          />
                          <label>West Edmonton</label>
                        </div>
                        <p>At this time we do not offer shipping</p>

                        {/* <input
                      name="giftMessage"
                      placeholder="Gift Message:"
                      type="text"
                      onChange={e => setGiftMessage(e.target.value)}
                    /> */}

                        <input
                          className={`submit`}
                          type="submit"
                          value="Checkout"
                        />
                        {status === "missing-items" && (
                          <p>Your cart is empty.</p>
                        )}
                      </form>
                    </FixMe>
                    {/* <button onClick={clearCart}>Remove all items</button> */}
                  </MainWrap>
                </animated.div>
              )}
            </>
          )
        })}

        {/* <button onClick={handleSubmit}>Checkout</button> */}
      </CartWrapper>
    </>
  )
}
